import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { JobApplicationsService } from 'src/services/jobApplications.service';
import { FeedbackService } from '../../services/feedback.service';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Constants } from 'src/services/constants.service';
import { NotificationService } from 'src/services/notification.service';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>;
  dtTrigger: Subject<any> = new Subject();
  dtTrigger1: Subject<any> = new Subject();
  selectedFilterStatus: string = 'all';
  myFeedBack: any[] = [];
  allFeedBack: any[] = [];
  
  dtOptions: any = {};
  dtOptions1: any = {};
  employeeList: any[] = [];
  selectedFeedbackType: string = 'Supervisor Feedback';
  selectedEmployeeId: string = '';
  feedbackDescription: string = '';
  isAnonymous: boolean = false;
  public logedInUser : number=0;

  appDetail: any;
  public loginUser: any = "";
  categoryName: string = ''; // Example category
  MyName: any;
  myFeedbackType: any;
  showFeedbackForm = false;
  public userList: any = [];
  public selectedAssignee: any = "";
  public assignedId: number = 0;
  public itemList = [];

  
  constructor( 
    private jobApplicationsService: JobApplicationsService,
    private feedbackService: FeedbackService,
    private noti: NotificationService,
    private appService: AppService,
    private userService: UserService,  ) {
    
   }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Peer Insights"
    });
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.loginUser.id=JSON.parse(localStorage.getItem('user'));
   
    this.getUsersList();

    this.getFeedBack();
    this.getUser();
    
    this.logedInUser=this.loginUser.userId;
  } 


  submitFeedback(): void {
    
    if (!this.selectedFeedbackType) {
      this.noti.notification("Error", "Select FeedbackType :", "danger");
      return;
    }
    
    if (!this.selectedEmployeeId) {
      this.noti.notification("Error", "Selected Employee Name:", "danger");
      return;
    }
    
    if (!this.feedbackDescription || this.feedbackDescription.trim().length === 0) {
      this.noti.notification("Error", " Add Feedback Description:", "danger");
      return;
    }
    const feedback = {
      userId: this.selectedEmployeeId,         
      type: this.selectedFeedbackType,        
      description: this.feedbackDescription,        
      isAnonymous: this.isAnonymous,          // Whether the feedback is anonymous
      feedBackUserId: this.loginUser.userId            
      };

      console.log(feedback);
    // Call the feedback service to save the feedback
    this.feedbackService.createFeedback(feedback).subscribe(
      (response) => {
       
        this.getFeedBack();
        this.noti.notification("Success", "FeedBack Successfully Add ", "success");
        this.resetForm(); // Optional: Reset the form after successful submission
      },
      (error) => {
        this.noti.notification("Error", "Error submitting feedback:", "danger");
        console.error('Error submitting feedback:', error);
        alert('Failed to submit feedback. Please try again.');
      }
    );
  }

  onFilterStatusChange(): void {
     this.selectedFilterStatus;
    
  }


  ngAfterViewInit(): void {
    // Trigger DataTable initialization for the single table
    if (this.dtTrigger) {
      this.dtTrigger.next();
    }
  }
  
  rerender(): void {
    this.dtElements.first.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy(); // Destroy the existing table instance
      this.dtTrigger.next(); // Reinitialize the table
    });
  }
  
  getFeedBack(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      dom: 'rtlip',
      pageLength: 10,
      order: [[3, 'desc']],
      processing: true,
      destroy: true,
      columnDefs: [
        { width: '15%', targets: 0 },
        { width: '20%', targets: 1 },
        { width: '55%', targets: 2 },
        {width: '10%', targets: 3}
      ]
    };
  
    this.feedbackService.getFeedback(this.loginUser.userId,this.selectedFilterStatus).subscribe(
      (response) => {
        if (response.status === 'ok') {
          console.log()
          this.myFeedBack = response.data;
          this.rerender(); // Re-render the single table
        } else {
          console.warn('Unexpected response status:', response.status);
        }
      },
      (error) => {
        if (error.status === 401) {
          this.noti.notification("Error", "Session expired. Please log in again.", "danger");
          console.log('Unauthorized access');
        } else {
          console.error('Error fetching feedback:', error);
        }
      }
    );
  }
  
  filterApplications() {
    
   this.getFeedBack();
  }
  // getAllFeedBack(): void {
  //   this.dtOptions1 = {
  //     pagingType: 'full_numbers',
  //     dom: 'frtlip',
  //     pageLength: 10,
  //     order: [[2, 'desc']],
  //     processing: true,
  //     destroy: true,
  //     columnDefs: [
  //       { width: '20%', targets: 0 },
  //       { width: '20%', targets: 1 },
  //       { width: '38%', targets: 2 }
  //     ]
  //   };

  //   this.feedbackService.getAllFeedback(this.loginUser.userId).subscribe(response => {
  //     if (response.status === 'ok') {
  //       this.allFeedBack = response.data;
  //       this.rerender(1);
  //     }
  //   }, error => {
  //     if (error.status === 401) {
  //       console.log('Unauthorized access');
  //     }
  //   });
  // }

  toggleFeedbackForm(): void {
    this.showFeedbackForm = !this.showFeedbackForm;
  }
 showAddFeedback() {
 
  this.employeeList;
  

}
getUser() {
  if (Constants.adminLevelUsers.length === 0) {
    this.userService.getallUsers().subscribe(
      response => {
        if (response.status === "ok") {
          Constants.adminLevelUsers = this.userList = response.data.value;

          // Set the first user as the default selected user
          this.selectedEmployeeId = this.userList[0].userId; // Updates ngModel binding
          this.selectedAssignee = this.userList[0]; // Updates dropdown UI
          this.assignedId = this.userList[0].userId;

          // Prepare dropdown items
          this.itemList = this.userList.map(user => ({
            id: user.userId,
            itemName: user.firstName,
            profilePhoto: user.profilePhoto,
          }));
        }
      },
      error => {
        if (error.status === 401) {
          // Handle unauthorized error
        }
      }
    );
  } else {
    // Use cached users from Constants
    this.userList = Constants.adminLevelUsers;

    // Set the first user as the default selected user
    this.selectedEmployeeId = this.userList[0].userId; // Updates ngModel binding
    this.selectedAssignee = this.userList[0]; // Updates dropdown UI
    this.assignedId = this.userList[0].userId;

    // Prepare dropdown items
    this.itemList = this.userList.map(user => ({
      id: user.userId,
      itemName: user.firstName,
      profilePhoto: user.profilePhoto,
    }));
  }
}

showAllFeedback() {
  
}
showMyFeedback() {
  if (this.loginUser && this.loginUser.userId) {
    this.getFeedBack(); // Call getFeedBack with the userId
  } else {
    console.error('User ID is not available for fetching feedback.');
  }
}



getUsersList() {
  this.userService.getAdminlevel().subscribe(response => {
 
        if (response.status === "ok") {
          this.employeeList = response.data.value;
        }
      },
      (error) => {
        console.error('Error fetching users:', error);
        if (error.status === 401) {
          this.appService.signOut();
        }
      }
    );
  }

  selectPeople(user): void {
    this.selectedAssignee = user; // Updates the selected user object
    this.selectedEmployeeId = user.userId; // Updates the selected employee ID
  }
  
  // Submit feedback to the backend

  
  // Reset form fields after submission
  resetForm(): void {
    this.selectedFeedbackType = 'Supervisor Feedback';
    this.selectedEmployeeId = '';
    this.feedbackDescription = '';
    this.isAnonymous = false;
   
  }
  filterReset() {
    $("#filterStatusOpt").val("all");
    this.getFeedBack()
  }
  
}
