import { Component, OnInit } from '@angular/core';

import { UserService } from 'src/services/user.service';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { NotificationService } from 'src/services/notification.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/services/dashboard.service';
import { Constants } from 'src/services/constants.service';
import { TasksService } from 'src/services/tasks.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.css']
})
export class NewReportComponent implements OnInit {

public selectedUser;
  public itemList = [];
  public selectedAssignee: any = "";
  public assignedId: number = 0;
  beAssigned: any[] = [];
  public taskDate: any;
  public taskStartDate:any;
  public dueDate:any;
  public addDate :any;
  public today1: any;
  public filterAssignedId: number = 0;
  public selectedTaskIds: string[] = [];
  public detail: any = '';
  public loginId: number = 0;
  public UserloginId: number = 0;
  public loginUser: any = "";
  public selectedTaskId: string = ''; 


  public startDate = moment().format('MM/DD/YYYY');
  public endDate = moment().format('MM/DD/YYYY');
  public selectDate: any;
  public userTasks: any = [];
  public userList: any = [];
  public taskHtml = "";
  public projectHtml = "";
  public hours = 0;
 
  public estHours = 0;


  
  constructor(
    private appService: AppService,
   
    private tasksService: TasksService,
    private userService: UserService,
    private dashboardService: DashboardService,
    private noti: NotificationService,
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Employee Report"
    });
   
    this.today1 = moment().format('YYYY-MM-DD');
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.UserloginId = this.loginUser.userId;
  }

  // onUserSelect(userId: number) {
  //   // Find the selected user
  //   this.selectedUser = this.userList.find(user => user.userId === Number(userId));

  //   if (this.selectedUser) {
  //     // Add to beAssigned list if not already added
  //     if (!this.beAssigned.some(user => user.userId === this.selectedUser.userId)) {
  //       this.beAssigned.push(this.selectedUser);
  //       console.log(`User ${this.selectedUser.firstName} assigned.`);
  //     } else {
  //       console.log(`User ${this.selectedUser.firstName} is already assigned.`);
  //     }
  //   }
  // }

  
onDateSelect(event) {
  this.taskDate = moment().year(event.year).month(event.month - 1).date(event.day).format('MM/DD/YYYY');

  console.log(this.taskDate);

}

onStartDateSelect(event) {
  this.taskStartDate =  moment().year(event.year).month(event.month - 1).date(event.day).format('MM/DD/YYYY');

  console.log(this.taskStartDate);
}

onActionClick() {
  this.taskHtml = "";
  this.userList = [];
  $("#Export").css("display", "none");
  if (!this.taskDate) { // This checks for undefined, null, or 0
    this.taskDate = null;
}
if (!this.taskStartDate) { // This checks for undefined, null, or 0
    this.taskStartDate = null;
}
if (!this.selectedUser){
  this.noti.notification("Error", "Select Employee ", "danger");
}
  if(this.taskDate < this.taskStartDate){
  this.noti.notification("Error", "Your start Date Smaller Then End Date  ", "danger");
  return 
  }
  // Call the service to get the filtered report by the user
  this.dashboardService.getFilterReportByUser(this.taskDate, this.taskStartDate, this.selectedUser.userId).subscribe(res => {
    if (res.status === "ok") {
      let data = res.data;
      this.userTasks = data;
      console.log(this.userTasks);

      // Check if data exists
      if (data.length === 0) {
        document.getElementById("no-data-found").innerHTML = "No Records Found.";
        document.getElementById("daily-task-list").innerHTML = "";         
        $(".custom-loader").css("display", "none");
        $(".loader-response").css("display", "block");
      } else {
        // Start creating the task table
  // Start creating the task table
// Start creating the task table
// this.taskHtml += "<table class='table table-bordered'><tr style='background-color:#d7d7d7;'><td>Description</td><td>Task Log Description</td><td style='width: 150px;'>Date Updated</td><td style='width:20px;'>Approved By</td><td style='width:140px;'>Is Approved</td></tr>";
// // <td style='width: 70px;'>Est</td><td style='width: 70px;'>Act</td><td style='width: 230px;'>Project Name</td>
// // Loop through user tasks and add to table for the selected user

// for (let t = 0; t < this.userTasks.length; t++) {
//   if (this.userTasks[t].userId === this.selectedUser.userId) {
//     // Start the row
//     this.taskHtml += "<tr>";
    
//     // Display project name, estimated hours, task title, and description
//     this.taskHtml += `<td>${this.userTasks[t].projectName}${this.userTasks[t].estimatedHours}
//                       <b>${this.userTasks[t].taskTitle}</b><br>${this.userTasks[t].description}</td>`;
    
//     // Handle task logs
//     if (this.userTasks[t].taskLogs && this.userTasks[t].taskLogs.length > 0) {
//       for (let i = 0; i < this.userTasks[t].taskLogs.length; i++) {
    
//         this.taskHtml += "<td></td>"
//         this.taskHtml += `<td>
//           Date: ${new Date(this.userTasks[t].taskLogs[i].dateAdded).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}<br>
//           Description: ${this.userTasks[t].taskLogs[i].description}<br>
//           Hours: ${this.userTasks[t].taskLogs[i].hours}
//         </td>`;
//         this.taskHtml += "<tr>"
//       }
//     } else {
//       this.taskHtml += "<td colspan='6'>No logs available.</td>";
//     }
    
//     // Display Date Updated if isCompleted is true
//     if (this.userTasks[t].isCompleted) {
//       this.taskHtml += `<td style='width: 150px;'>${this.userTasks[t].taskDateUpdated 
//         ? new Date(this.userTasks[t].taskDateUpdated).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' }) 
//         : "N/A"}</td>`;
//     } else {
//       this.taskHtml += "<td style='width: 150px;'>Not Completed</td>";
//     }

//     // Display Approved By
//     if (this.userTasks[t].approvedBy === null) {
//       this.taskHtml += "<td style='width: 150px;'>Not Approved</td>";
//     } else {
//       this.taskHtml += `<td style='width: 150px;'>${this.userTasks[t].approvedBy}</td>`;
//     }

//     // Approve checkbox handling
//     if (this.userTasks[t].taskLogs && this.userTasks[t].taskLogs.length > 0) {
//       for (let i = 0; i < this.userTasks[t].taskLogs.length; i++) {
//         this.taskHtml += `<td>
//           <label style='display: flex; align-items: center;'>
//             <input type='checkbox' name='isApproved' 
//                    value='${this.userTasks[t].id || ''}' 
//                    style='position: static;' 
//                    class='task-checkbox' 
//                    ${this.userTasks[t].isApproved ? 'checked' : ''}>
//           </label>
//         </td>`;
//       }
//     }
//     this.taskHtml += "</tr>";

//     // Aggregate hours
//     this.hours += parseFloat(this.userTasks[t].hours);
//     this.estHours += parseFloat(this.userTasks[t].estimatedHours);
//   }
// }

this.taskHtml += "<table class='table table-bordered'>";
this.taskHtml += "<tr style='background-color:#d7d7d7;'>";
this.taskHtml += "<td>Task Title</td>";
this.taskHtml += "<td>TaskLog Description</td>";
this.taskHtml += "<td>Hours</td>";
this.taskHtml += "<td>Date</td>";
this.taskHtml += "<td>Approved By</td>";
this.taskHtml += "</tr>";

// Loop through user tasks and add to table for the selected user
for (let t = 0; t < this.userTasks.length; t++) {
  if (this.userTasks[t].userId === this.selectedUser.userId) {
    // Start the row for task details
    this.taskHtml += "<tr>";
    let countvalue = 0;
    if(this.userTasks[t].isBug == true){
    this.taskHtml += `<td style="padding: 0px 3px !important; width: 322px;"> 
                    &lt;${this.userTasks[t].projectName}&gt; 
                    &lt;${this.userTasks[t].estimatedHours}&gt; <i class="la la-bug"></i><br>
                    ${this.userTasks[t].taskTitle}
                  </td>`;
    }
    else{
      this.taskHtml += `<td style="padding: 0px 3px !important; width: 322px;"> 
      &lt;${this.userTasks[t].projectName}&gt; 
      &lt;${this.userTasks[t].estimatedHours}&gt;<br>
      ${this.userTasks[t].taskTitle}
    </td>`;
    }

    // Handle task logs (only showing description)
    if (this.userTasks[t].taskLogs && this.userTasks[t].taskLogs.length > 0) {
      for (let i = 0; i < this.userTasks[t].taskLogs.length; i++) {
        if( countvalue == 0){
          console.log('First Count',countvalue);
        this.taskHtml += `<td>${this.userTasks[t].taskLogs[i].description}</td>`;
        this.taskHtml += `<td style="width: 10px;">${this.userTasks[t].taskLogs[i].hours}</td>`;
        this.taskHtml += `<td style="width: 122px;">${new Date(this.userTasks[t].taskLogs[i].dateUpdated).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</td>`;
        if(this.userTasks[t].taskLogs[i].userId != this.UserloginId){
        if (this.userTasks[t].taskLogs[i].isApproved == false) {
          this.taskHtml += `<td style="width: 150px;">
            <label style='display: flex; align-items: center;'>
              <input type='checkbox' name='isApproved' 
                     value='${this.userTasks[t].taskLogs[i].id || ''}' 
                     style='position: static;margin-right: 6px;' 
                     class='task-checkbox'>
            </label>
          </td>`;
        } else {
          this.taskHtml += `<td style="width: 150px;">
            <label style='display: flex; align-items: center;'>
              <input type='checkbox' name='isApproved' 
                     value='${this.userTasks[t].taskLogs[i].id || ''}' 
                     style='position: static;margin-right: 6px;' 
                     class='task-checkbox' 
                     checked>
              ${this.userTasks[t].taskLogs[i].approvedBy}
            </label>
          </td>`;
        }
      }else{
         this.taskHtml += `<td style="width: 150px;"></td>`
      }
      
      this.hours += parseFloat(this.userTasks[t].taskLogs[i].hours);
      
      }else{
        this.taskHtml += "<tr>"; 
         
        this.taskHtml += "<td></td>"; 
        this.taskHtml += `<td>${this.userTasks[t].taskLogs[i].description}</td>`;
        this.taskHtml += `<td>${this.userTasks[t].taskLogs[i].hours}</td>`;
        this.taskHtml += `<td>${new Date(this.userTasks[t].taskLogs[i].dateUpdated).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: '2-digit' })}</td>`;
       if(this.userTasks[t].taskLogs[i].userId != this.UserloginId){
        if (this.userTasks[t].taskLogs[i].isApproved == false) {
          this.taskHtml += `<td style="width: 150px;">
            <label style='display: flex; align-items: center;'>
              <input type='checkbox' name='isApproved' 
                     value='${this.userTasks[t].taskLogs[i].id || ''}' 
                     style='position: static;margin-right: 6px;' 
                     class='task-checkbox'>
            </label>
          </td>`;
        } else {
          this.taskHtml += `<td style="width: 150px;">
            <label style='display: flex; align-items: center;'>
              <input type='checkbox' name='isApproved' 
                     value='${this.userTasks[t].taskLogs[i].id || ''}' 
                     style='position: static;margin-right: 6px;' 
                     class='task-checkbox' 
                     checked>
              ${this.userTasks[t].taskLogs[i].approvedBy}
            </label>
          </td>`;
        }
      }else{
         this.taskHtml += `<td style="width: 150px;"></td>`
      }
        
        this.taskHtml += "</tr>";
        this.hours += parseFloat(this.userTasks[t].taskLogs[i].hours);
      }  
      
  
        countvalue= countvalue + 1;
        console.log(countvalue);
      }
    } else {
      // If no logs, display message
      this.taskHtml += "<td>No logs available.</td>";
    }

    this.taskHtml += "</tr>";
    this.estHours += parseFloat(this.userTasks[t].estimatedHours);  // Close row for task
  }
}
// Add total hours row if any tasks exist for the user
if (this.hours > 0) {
  this.taskHtml += "<tr> <th class='border-left-0 border-bottom-0'>Total Hours</th><th class='border-bottom-0 border-right-0'>" + this.estHours + "</th><th class='border-bottom-0 border-right-0'>" + this.hours + "</th></tr>";
  // <th class='border-bottom-0'>" + this.estHours + "</th>
} else {
  this.taskHtml += "<tr><th class='border-0 text-danger'>No Entry</th></tr>";
}
console.log(this.hours + " hours");

this.taskHtml += "</table>";
setTimeout(() => {
  const checkboxes = document.querySelectorAll('.task-checkbox') as NodeListOf<HTMLInputElement>;
  for (let i = 0; i < checkboxes.length; i++) {
    checkboxes[i].addEventListener('change', (event: Event) => {
      const target = event.target as HTMLInputElement;
      // Ensure the value always exists
      if (target.value) {
        console.log(target.value,'this is target Value');
        this.updateSelectedTasks(target, target.value);
      }
    });
  }
}, 0);
// Reset hours and estimated hours for next rendering
this.hours = 0;
this.estHours = 0;

// Render the final task HTML
document.getElementById("daily-task-list").innerHTML = this.taskHtml;
document.getElementById("no-data-found").innerHTML = "";
$(".custom-loader").css("display", "none");
$(".loader-response").css("display", "block");
console.log("Function End " + this.filterAssignedId);

      
      }
      this.getUserAndSelect(this.filterAssignedId);
      $("#Export").css("display", "block");
      $("#Approved").css("display", "block");

    }
  }, error => {
    if (error.status === 401) {
      // Handle unauthorized error
    }
  });
}
updateSelectedTasks(checkbox: HTMLInputElement, taskId: string): void {
  console.log('this is TaskId',taskId);
  if (checkbox.checked) {
    this.selectedTaskId = taskId;
    this.approvedtasks();
  } else {
    this.selectedTaskId = taskId;
    this.approvedtasks();
  }
  console.log('Selected TaskId:', this.selectedTaskId);
  
}

// updateSelectedTasks(checkbox: HTMLInputElement, taskId: string): void {
//   if (checkbox.checked) {
//     // Add the taskId to the array if checked
//     if (!this.selectedTaskIds.includes(taskId)) {
//       this.selectedTaskIds.push(taskId);
//     }
//   } else {
//     // Remove the taskId from the array if unchecked
//     const index = this.selectedTaskIds.indexOf(taskId);
//     if (index > -1) {
//       this.selectedTaskIds.splice(index, 1);
//     }
//   }
//   console.log("Selected TaskIds:", this.selectedTaskIds);
// }
approvedtasks() {
  if (this.selectedTaskId && this.selectedTaskId.length > 0) {
    this.detail = this.selectedTaskId;  // Pass only IDs
    
    this.tasksService.approvedTask(this.selectedTaskId,this.UserloginId ).subscribe(
      (res) => {
        if (res.status === "ok") {
         
          this.onActionClick()
          this.selectedTaskId ='';
          // Success logic here
          console.log("Tasks approved successfully:", res.data);
          this.noti.notification("Success", "Tasks approved successfully!", "success");
        } else {
          // Handle unexpected response status
          console.error("Unexpected response:", res);
          this.noti.notification("Error", "Unexpected error occurred.", "danger");
        }
      },
      (error) => {
        // Error handling
        if (error.status === 401) {
          console.error("Unauthorized access:", error);
          this.noti.notification("Error", "Unauthorized access. Please login again.", "danger");
        } else {
          console.error("Error occurred while approving tasks:", error);
          this.noti.notification("Error", "An error occurred while processing your request.", "danger");
        }
      }
    );
  } else {
    // If no tasks are selected
    console.warn("No tasks selected for approval.");
    this.noti.notification("Warning", "Please select tasks to approve.", "warning");
  }
}


generateExcelFile() {
  // Prepare the data to export
  let exportData = [];
  
  // Add headers to the export data
  exportData.push(["Name", "Project Name", "Estimated Hours", "Actual Hours", "Task Title", "Description"]);
  
  // Variables to hold the sum of estimatedHours and actual hours
  let totalEstimatedHours = 0;
  let totalActualHours = 0;
  
  // Loop through the userTasks to prepare the data for Excel
  for (let task of this.userTasks) {
    if (task.userId === this.selectedUser.userId) {
      let row = [
        this.selectedUser.firstName,      // Name
        task.projectName,                 // Project Name
        task.estimatedHours,              // Estimated Hours
        task.hours,                       // Actual Hours
        task.taskTitle,                   // Task Title
        task.description                  // Description in a separate column
      ];
      exportData.push(row);

      // Accumulate the total estimated hours and actual hours
      totalEstimatedHours += parseFloat(task.estimatedHours);
      totalActualHours += parseFloat(task.hours);
    }
  }

  // Add a row for the total sum of estimated and actual hours
  exportData.push([
    "",                      
    "Total",                  
    totalEstimatedHours,       
    totalActualHours,         
    "",                       
    ""                       
  ]);

  // Create a new workbook and worksheet
  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData);
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'User Tasks');

  let formattedTaskDate = this.taskDate ? new Date(this.taskDate).toLocaleDateString().replace(/\//g, '-') : new Date().toLocaleDateString().replace(/\//g, '-');
  let formattedTaskStartDate = this.taskStartDate ? new Date(this.taskStartDate).toLocaleDateString().replace(/\//g, '-') : new Date().toLocaleDateString().replace(/\//g, '-');
  
  XLSX.writeFile(workbook, `${this.selectedUser.firstName}(${formattedTaskStartDate}-${formattedTaskDate}).xlsx`);
  
  
}


getUserAndSelect(userId: number) {
  // Check if admin-level users list is empty and fetch if necessary
  if (Constants.adminLevelUsers.length === 0) {
    if (this.loginUser.accessLevel == 'super_admin') {
      this.loginId = 0;
    } else {
      this.loginId = this.loginUser.userId;
    }
    this.userService.getAdminlevel().subscribe(response => {
      if (response.status === "ok") {
        // Populate Constants and userList with fetched data
        Constants.adminLevelUsers = this.userList = response.data.value;
        this.selectedAssignee = this.userList[0];
        this.assignedId = this.userList[0].userId;

        // Populate itemList for other purposes if needed
        for (var i = 0; i < this.userList.length; i++) {
          this.itemList.push({
            "id": this.userList[i].userId,
            "itemName": this.userList[i].firstName,
            "profilePhoto": this.userList[i].profilePhoto
          });
        }

        // Handle user selection if a valid userId is passed
        this.selectUserById(userId);
      }
    }, error => {
      if (error.status === 401) {
        console.error("Unauthorized error.");
      }
    });
  } else {
    // If data is already available, use the cached data
    this.userList = Constants.adminLevelUsers;
    this.selectedAssignee = this.userList[0];
    this.assignedId = this.userList[0].userId;

    // Populate itemList for other purposes if needed
    for (var i = 0; i < this.userList.length; i++) {
      this.itemList.push({
        "id": this.userList[i].userId,
        "itemName": this.userList[i].firstName,
        "profilePhoto": this.userList[i].profilePhoto
      });
    }

    // Handle user selection if a valid userId is passed
    this.selectUserById(userId);
  }
}

// New helper method to handle user selection
selectUserById(userId: number) {
  // Find the selected user based on userId
  this.selectedUser = this.userList.find(user => user.userId === Number(userId));

  if (this.selectedUser) {
    // Add to beAssigned list if not already added
    if (!this.beAssigned.some(user => user.userId === this.selectedUser.userId)) {
      this.beAssigned.push(this.selectedUser);
      console.log(`User ${this.selectedUser.firstName} assigned.`);
    } else {
      console.log(`User ${this.selectedUser.firstName} is already assigned.`);
    }
  }
}

  



  // getUser() {
  //   if (Constants.adminLevelUsers.length == 0) {
  //     this.userService.getAdminlevel().subscribe(response => {
  //       if (response.status == "ok") {
  //         Constants.adminLevelUsers = this.userList = response.data.value;
  //         this.selectedAssignee = this.userList[0];
  //         this.assignedId = this.userList[0].userId;
  //         for (var i = 0; i < this.userList.length; i++) {
  //           this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto });
  //         }
  //       }
  //      console.log("this.is"+this.filterAssignedId)
  //     }, error => {
  //       if (error.status == 401) { }
  //     });
  //   } else {
  //     this.userList = Constants.adminLevelUsers;
  //     this.selectedAssignee = this.userList[0];
  //     this.assignedId = this.userList[0].userId;
  //     for (var i = 0; i < this.userList.length; i++) {
  //       this.itemList.push({ "id": this.userList[i].userId, "itemName": this.userList[i].firstName, "profilePhoto": this.userList[i].profilePhoto });
  //     }
  //   }
  // }
}
