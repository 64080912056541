import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { JobApplicationsService } from 'src/services/jobApplications.service';
import { NotificationService } from 'src/services/notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/services/user.service';
import { DataTableDirective } from 'angular-datatables';
import { AppService } from 'src/app/app.service';
import { GlobalVariable } from "src/app/globals";
import { Subject } from 'rxjs';
declare var $;
import { Constants } from 'src/services/constants.service';
import * as moment from 'moment';

@Component({
  selector: 'app-job-applications',
  templateUrl: './job-applications.component.html',
  styleUrls: ['./job-applications.component.css']
})
export class JobApplicationsComponent implements OnInit {
  public loginUser: any = '';
  public appDetail: any = '';
  public applicationFeedback: any = '';
  public totalCount = 0;
  public applicationCount: any;
  public pageNumber = 1;
  public pageSize = 25;
  public sortOrder = "";
  public sortBy = "";
  public search = "";
  public applicationsList = [];
  public year: any = moment().format('YYYY');
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  public userList: any = [];
  
  public loginUserId: number = 0;
  public applicationId: number = null;
 
  public resumeAddObj = {
    Name: "",
    Email: "",
    Phone: null,
    DocumentUrl: "",
    AssignById: null,
    UserId: null,
    ResumeStatus: "pending",
    AssignmentNotes: "",
    PreInterviewNotes: "",
    Position: "",
    Source: "portal",
    DateAdded: new Date(),
    DateUpdated: new Date()
  };
 
  public filterStatus = "";
  public filterAssigned = 0;
  public isSelected: boolean = false;
  public assignedList: any = "";

  //Add resume fileds
  public cName: string = "";
  public cEmail: string = "";
  public cPhone: string = "";
  public cPosition: string = "";
  public localAttachmentsName = "";
  public files: FileList;
  public formData: FormData = new FormData();

  private baseUrl = GlobalVariable.BASE_API_URL;
  // public URL: string = this.baseUrl + "api/JobApplications/resumepost";

  constructor(
    private http: Http,
    private appService: AppService,
    private jobApplicationsService: JobApplicationsService,
    private userService: UserService,
    private noti: NotificationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Careers"
    });
    this.getUsersList();
    this.getApplicationCount();
    this.getApplications();
    this.getAssignedCount();
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    $('[data-toggle="tooltip"]').tooltip();

    $('#addResumeModal').modal({
      backdrop: 'static',
      keyboard: false,
      show: false
    });
    $('#candidateName').on("keyup", function () {
      var searchvalue = $('#candidateName').val();
    });
  }

  resumeSeenGet(id) {
    let data = JSON.parse(localStorage.getItem('resumeAction'));
    if (data != null) {
      var filteredEvents = data.filter(function (event) {
        return event.id == id;
      });

      if (filteredEvents == null || filteredEvents == "") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getUsersList() {
    if (Constants.adminLevelUsers.length == 0) {
      this.userService.getAdminlevel().subscribe(response => {
        if (response.status == "ok") {
          if (response.data.value.length > 0) {
            this.userList = response.data.value;
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.userList = Constants.adminLevelUsers;
    }
  }

  getAssignedCount() {
    this.jobApplicationsService.getAssignedCount().subscribe((response) => {
      if (response.status == "ok") {
        this.assignedList = response.data;
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  getApplicationCount() {
    this.jobApplicationsService.getApplicationCount().subscribe((response) => {
      if (response.status == "ok") {
        this.applicationCount = response.data;
      }
    }, error => {
      if (error.status == 401) { }
    });
  }

  getApplications() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.pageSize,
      order: [[3, 'desc']],
      // "destroy": true,  
      dom: 'frtlip',//'Bfrtip',   
      "columnDefs": [
        { "width": "20%", "targets": 0 },
        { "width": "11%", "targets": 1, "orderable": false },
        { "width": "12%", "targets": 2, "orderable": false },
        { "width": "12%", "targets": 3, "orderable": false },
        { "width": "13%", "targets": 4, "orderable": false },
        { "width": "13%", "targets": 5, "orderable": false },
        { "width": "10%", "targets": 6 },
        { "width": "9%", "targets": 7, "orderable": false }
      ],
      processing: false,
      serverSide: true,
      ajax: (dTParameters: any, callback) => {
        this.pageSize = dTParameters['length'];
        this.pageNumber = dTParameters['start'] / this.pageSize + 1;
        this.sortOrder = dTParameters['order'][0].dir;
        this.search = dTParameters['search']['value'];
        let column = dTParameters['order'][0].column;
        switch (column) {
          case 0:
            this.sortBy = "Name";
            break;
          case 6:
            this.sortBy = "DateAdded";
            break;
          // case 6:
          //   this.sortBy = "DateUpdated";
          //   break;
          default:
            this.sortBy = "DateAdded";
        }
        if (this.loginUser.accessLevel != 'super_admin' && this.loginUser.accessLevel != 'hr_manager') {
          this.loginUserId = this.loginUser.userId;
          this.sortBy = "DateUpdated";
        }
        this.applicationsList = [];
        this.jobApplicationsService.getApplications(this.pageNumber, this.pageSize, this.sortOrder, this.sortBy, this.search, this.loginUserId, this.filterStatus, this.filterAssigned).subscribe(response => {
       // console.log(response.data);
          if (response.status == "ok") {
            if (response.data.value.length > 0) 
              {
               $(".custom-loader").css("display", "none");
                console.log('this is application details',response.data.value[0]);
                console.log('After',this.totalCount);
              this.totalCount = response.data.value[0].totalRecords;
              console.log(this.totalCount);
            } else {
              this.totalCount = 0;
            }
            let _that = this;
            response.data.value.forEach(function (value) {
              let data = value;
              if (data.parseMetaData != null && data.parseMetaData != "") {
                if (data.parseMetaData.skills != null && data.parseMetaData.skills != "") {
                  if (data.parseMetaData.skills != "" && data.parseMetaData.skills != null) {
                    data.parseMetaData.skills = JSON.parse(data.parseMetaData.skills);
                    data.parseMetaData.skills = data.parseMetaData.skills.filter(function (x) { return x !== "" });
                  }
                }
                
              }
              _that.applicationsList.push(value);
            });

            // this.applicationsList = response.data.value;

            if (this.applicationsList.length != 0) {
              setTimeout(() => {
                $(".dataTables_empty").css("display", "none");
              }, 200);
            }
            callback({
              recordsTotal: this.totalCount,
              recordsFiltered: this.totalCount,
              data: []
            });
          }
        }, error => {
          if (error.status == 401) { }
        });
      },
      columns: [
        { "data": 'name' },
        { "data": 'position', "orderable": false },
        { "data": 'skills', "orderable": false },
        { "data": 'userId', "orderable": false },
        { "data": 'resumeStatus', "orderable": false },
        { "data": 'matchedUsers', "orderable": false },
        { "data": 'dateAdded' },
        { "data": 'id', "orderable": false }
      ]
    };
  }
  confirmDelete(id) {
    this.applicationId = id;
    $("#deleteModal").modal("show");
  }
  deleteApplication() {
    this.jobApplicationsService.deleteApplication(this.applicationId).subscribe(res => {
      if (res.status == "ok") {
        for (var i = 0; i < this.applicationsList.length; i++) {
          if (this.applicationsList[i].id == this.applicationId) {
            this.applicationsList.splice(i, 1);
          }
        }
        this.rerender();
        this.noti.notification("Success", "Application Deleted Successfully", "success");
      } else {
        this.rerender();
        this.noti.notification("Error", "Cannot Delete This Application.", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#deleteModal").modal("hide");
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  filterApplications() {
    this.rerender();
  }

  filterReset() {
    this.filterStatus = "";
    this.filterAssigned = 0;
    this.pageNumber = 1;
    this.pageSize = 1;
    this.rerender();
  }

  toggleCollapse() {
    if (this.isSelected) {
      this.isSelected = false;
    } else {
      this.isSelected = true;
    }
  }

  addNoteResume() {
    $(".submit-note-btn").prop('disabled', true);
    $(".loading-request-spin").show();
    $(".loading-request-text").hide();
    let isvalid = this.validateResume();
    if (isvalid) {
      this.resumeAddObj.Name = this.cName;
      this.resumeAddObj.Email = this.cEmail;;
      this.resumeAddObj.Phone = this.cPhone;
      this.resumeAddObj.Position = this.cPosition;
      var details = JSON.stringify(this.resumeAddObj);
      this.formData.append('Param', details);

      this.http.post(this.baseUrl + "api/JobApplications/resumepost", this.formData)
        .subscribe(response => {
          let data = response.json();
          if (data.status == "ok") {
            this.rerender();
            $(".loading-request-spin").hide();
            $(".loading-request-text").show();
            $("#addResumeModal").modal("hide");
            $(".submit-note-btn").prop('disabled', false);
            this.noti.notification("Success", "Resume Added Successfully", "success");

            this.formData.delete('Files');
            this.formData.delete('Param');

          } else {
            if (data.data == "File uploading error") {
              this.noti.notification("Success", "Please check your internet connection", "danger");
            } else {
              this.noti.notification("Success", "Error Resume Adding", "danger");
            }
            this.formData.delete('Param');
            $(".loading-request-spin").hide();
            $(".loading-request-text").show();
            $(".submit-note-btn").prop('disabled', false);
          }

        }, (err: HttpErrorResponse) => {
          this.formData.delete('Param');
          $(".loading-request-spin").hide();
          $(".loading-request-text").show();
          $(".submit-note-btn").prop('disabled', false);
          this.noti.notification("Success", "Error Resume Adding", "danger");
        });
    } else {
      $(".submit-note-btn").prop('disabled', false);
      $(".loading-request-spin").hide();
      $(".loading-request-text").show();
    }
  }

  validateResume() {
    if (!(!!this.cName.trim())) {
      this.noti.notification("Error", "Please enter name", "danger");
      return false;
    }
    // if (!!this.cEmail.trim()) {
    this.cEmail = this.cEmail.replace(/\s/g, '');
    if (!(/^([a-zA-Z0-9_\.\-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/.test(this.cEmail))) {
      this.cEmail = "";
      this.noti.notification("Error", "Please enter valid email address", "danger");
      return false;
    }
    // }
    // if (!!this.cPhone.trim()) {
    this.cPhone = this.cPhone.replace(/\s/g, '');
    if (!(/^((\+92)|(0092)|(92))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(this.cPhone))) {
      this.cPhone = "";
      this.noti.notification("Error", "Please enter valid phone number", "danger");
      return false;
    }
    // }
    if (!(!!this.cPosition.trim())) {
      this.noti.notification("Error", "Please enter position", "danger");
      return false;
    }
    if (!(!!this.localAttachmentsName.trim())) {
      this.noti.notification("Error", "Please select file", "danger");
      return false;
    }
    return true;
  }

  showAddModel() {
    this.cName = "";
    this.cEmail = "";
    this.cPhone = "";
    this.cPosition = "";
    this.localAttachmentsName = "";
    this.formData.delete('Files');
    this.formData.delete('Param');
    $("#addResumeModal").modal("show");
    setTimeout(() => {
      $(".loading-request-spin").hide();
      $(".submit-note-btn").prop('disabled', false);
    }, 280);
  }

  addFiles(event) {
    this.formData.delete('Files')
    this.files = event.target.files;
    for (let i = 0; i < this.files.length; i++) {
      this.localAttachmentsName = this.files[i].name;
      this.formData.append('Files', this.files[i], this.files[i].name);
    }
  }
}
