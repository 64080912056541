import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class TasksService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) {

  }

  getIssueCounts(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/getIssuecounts?id=${id}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTasksCounts(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/gettaskscounts?id=${id}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTaskList(pageNumber, pageSize, sortBy, sortOrder, id, filterUser,filterByUser, filterProject, filterStatus, filterBugs, filterDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&userId=${id}&filterUser=${filterUser}&filterByUser=${filterByUser}&filterProject=${filterProject}&filterStatus=${filterStatus}&filterBug=${filterBugs}&filterDate=${filterDate}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getIssueList(pageNumber, pageSize, sortBy, sortOrder, id, filterUser, filterProject, filterissueType,filterserverityType,filterbug, filterDate,search) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/getIssues?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&userId=${id}&filterUser=${filterUser}&filterProject=${filterProject}&filterissueType=${filterissueType}&filterserverityType=${filterserverityType}&filterbug=${filterbug}&filterDate=${filterDate}&search=${search}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  addTask(task) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks';
    return this.http.post(request, task, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTask(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks/taskwithlogs/' + id;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  updateTask(task) {
    let options = this.authService.setHeaderAuth();
    console.log('token',options);
    let request = this.baseApiUrl + 'api/tasks/' + task.Id;
    return this.http.put(request, task, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  approvedTask(task,userId) {
    console.log(task);
    let options = this.authService.setHeaderAuth();
    let requestData = {
      tasksId: task,    
      userId: userId  
  };
     console.log(requestData);
     let requestDatas = requestData.toString();
 
     let request = this.baseApiUrl + 'api/tasks/approvedtasks/' + task ;
    return this.http.put(request, requestData, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  updateMarkTask(task) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks/markComplete/' + task.Id;
    return this.http.put(request, task, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  deleteTask(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks/' + id;
    return this.http.delete(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTaskLogs(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/getlogs?id=${id}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  addTaskLog(taskLog) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks/log';
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(request, taskLog, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getTaskLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks/log/' + id;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  updateTaskLog(taskLog) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks/log/' + taskLog.id;
    return this.http.put(request, taskLog, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  deleteTaskLog(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/tasks/log/' + id;
    return this.http.delete(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getTasksFilter(startDate, endDate) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/gettasksbydates?startDate=${startDate}&endDate=${endDate}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getincompletetask() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/getincompletetask`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  // getAssignTask(id) {
  //   let options = this.authService.setHeaderAuth();
  //   let request = this.baseApiUrl + `api/tasks/getassigntasks?id=${id}`;
  //   return this.http.get(request, options).pipe(
  //     map(
  //       response => {
  //         return response.json()
  //       }
  //     ),
  //     catchError(err => {
  //       return "error";
  //     }
  //     )
  //   );
  // }

  getTaskAttachments(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/files?id=${id}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  deleteTaskFile(taskId) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/tasks/deletetaskfile?id=${taskId}`;
    return this.http.delete(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  // insertAllTasks() {
  //   let options = this.authService.setHeaderAuth();
  //   let request = this.baseApiUrl + 'api/tasks/insertalltasksindb';
  //   // let headers = new Headers({ 'Content-Type': 'application/json' });
  //   // let options = new RequestOptions({ headers: headers });
  //   return this.http.get(request, options).pipe(map(
  //     response => {
  //       return response.json()
  //     }
  //   ),
  //     catchError(err => {
  //       return "error";
  //     }
  //     ));
  // }
}
