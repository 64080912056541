import { NgbDateStruct, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/services/notification.service';
import { DashboardService } from 'src/services/dashboard.service';
import { Constants } from 'src/services/constants.service';
import { TasksService } from 'src/services/tasks.service';
import { UserService } from 'src/services/user.service';
import { TaskService } from 'src/services/task.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public allCounts: any;
  public onFirstSelection = true;
  public hoveredDate: NgbDate;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public startDateTimeline = moment().format('MM/DD/YYYY');
  public endDateTimeline = moment().format('MM/DD/YYYY');
  public userId = 0;
  public description = "";
  public userName = "";
  public userList: any = [];
  public lateUserList = [];
  public isRequestinglateList: boolean = false;
  lateUserDatepicker: NgbDateStruct;
  public lateUserDate: any;
  maxDate: any;
  public loginUser: any = '';
  //Tasks List
  public startDateTasks = moment().format('MM/DD/YYYY');
  public endDateTasks = moment().format('MM/DD/YYYY');
  public dateTasksList = '';
  public activeProjects = [];
  public isRequestingProjectsList: boolean = false;
  public isRequestingTaskList: boolean = false;
  public taskList: any = [];
  public inCompleteTask: any=[];
  public year: any = moment().format('YYYY');

  constructor(
    private dashboardService: DashboardService,
    private tasksService: TasksService,
    private noti: NotificationService,
    private userService: UserService,  
    private appService: AppService,
    private calendar: NgbCalendar
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Dashboard"
    });
    this.loginUser = JSON.parse(localStorage.getItem('user'));
    this.getStats();
    this.getLateUsers();
    this.getActiveProjects();
    this.changeTasksDate('today');
    $('[data-toggle="tooltip"]').tooltip();
    //  this.LoadWeather();
   this.getIncompleteTask();
  }

  // LoadWeather() {
  //   document.getElementById("weatherWidgetP").innerHTML = "";
  //   document.getElementById("weatherWidgetP").innerHTML = "<a class=\"weatherwidget-io\" href=\"https://forecast7.com/en/33d5773d02/rawalpindi/\" data-label_1=\"RAWALPINDI\" data-label_2=\"WEATHER\" data-icons=\"Climacons Animated\" data-mode=\"Forecast\" data-theme=\"pure\">RAWALPINDI WEATHER</a>";
  //   !function (d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (!d.getElementById(id)) { js = d.createElement(s); js.id = id; js.src = 'https://weatherwidget.io/js/widget.min.js'; fjs.parentNode.insertBefore(js, fjs); } }(document, 'script', 'weatherwidget-io-js');
  // }

  getStats() {
    if (Constants.allCounts) {
      this.allCounts = Constants.allCounts;
    } else {
      this.dashboardService.getCounts().subscribe(res => {
        if (res.status == "ok") {
          Constants.allCounts = this.allCounts = res.data;
        }
      }, error => {
        if (error.status == 401) { }
      });
    }

    if (Constants.adminLevelUsers.length == 0) {
      this.userService.getAdminlevel().subscribe(response => {
        if (response.status == "ok") {
          if (response.data.value.length > 0) {
            Constants.adminLevelUsers = this.userList = response.data.value;
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.userList = Constants.adminLevelUsers;
    }
  }

  // ngOnDestroy(): void {
  //   $('script[src="https://weatherwidget.io/js/widget.min.js"]').remove()
  // }

  userLateModelOpen() {
    this.lateUserDatepicker = this.calendar.getToday();
    this.maxDate = this.lateUserDatepicker;
    $("#lateCountModal").modal("show");
  }

  onDateSelectModel(event) {
    this.lateUserDate = moment().year(event.year).month(event.month - 1).date(event.day).format();
  }

  addLateUser() {
    let userId = $('#selectOptionServer').val();
    this.lateUserDate = moment().year(this.lateUserDatepicker.year).month(this.lateUserDatepicker.month - 1).date(this.lateUserDatepicker.day).format();
    var lateUser = {
      userId: userId,
      Description: this.description.trim(),
      count: 1,
      DateAdded: new Date(this.lateUserDate)
    };

    this.userService.addLateCount(lateUser).subscribe(res => {
      if (res.status == "ok") {
        Constants.lateUsers.length = 0;
        this.getLateUsers();
        this.noti.notification("Success", "Added Successfully", "success");
      } else {
        this.noti.notification("Error", "Error Adding Record ", "danger");
      }
    }, error => {
      if (error.status == 401) { }
    });
    $("#lateCountModal").modal("hide");
  }

  getLateUsers() {
    this.isRequestinglateList = true;
    this.lateUserList = [];
    if (Constants.lateUsers.length == 0) {
      let utcOffSet = moment().utcOffset();
      this.userService.getLateUsers(utcOffSet).subscribe(res => {
        this.isRequestinglateList = false;
        if (res.status == "ok") {
          Constants.lateUsers = this.lateUserList = res.data;
          this.lateUserList.sort(function (a, b) {
            if (a.count < b.count) { return 1; }
            if (a.count > b.count) { return -1; }
            return 0;
          });
        }
      }, error => {
        this.isRequestinglateList = true;
        if (error.status == 401) { }
      });
    } else {
      this.isRequestinglateList = false;
      this.lateUserList = Constants.lateUsers;
      this.lateUserList.sort(function (a, b) {
        if (a.count < b.count) { return 1; }
        if (a.count > b.count) { return -1; }
        return 0;
      });
    }
  }

  getActiveProjects() {
    this.isRequestingProjectsList = true;
    this.activeProjects = [];
    if (Constants.activeProjects.length == 0) {
      let utcOffSet = moment().utcOffset();
      this.dashboardService.getActiveProjects(utcOffSet).subscribe(res => {
        this.isRequestingProjectsList = false;
        if (res.status == "ok") {
          Constants.activeProjects = this.activeProjects = res.data;
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.isRequestingProjectsList = false;
      this.activeProjects = Constants.activeProjects;
    }
  }

  getDailytask(startDate, endDate) {
    this.isRequestingTaskList = true;
    this.tasksService.getTasksFilter(startDate, endDate).subscribe(res => {
      this.isRequestingTaskList = false;
      if (res.status == "ok") {
        this.taskList = res.data;
        this.taskList.sort(function (a, b) {
          if (a.assignedName < b.assignedName) { return -1; }
          if (a.assignedName > b.assignedName) { return 1; }
          return 0;
        });
      }
    }, error => {
      if (error.status == 401) { }
    });
  }
getIncompleteTask(){
  this.isRequestingProjectsList = true;
  this.tasksService.getincompletetask().subscribe(res => {
    if (res.status == "ok") {
      this.isRequestingProjectsList = false;
      this.inCompleteTask = res.data;
  
    }
  }, error => {
    if (error.status == 401) { }
  });
}


  changeTasksDate(data?: any) {
    let todayDate = moment().format('MM/DD/YYYY');
    let requestCall = false;
    if (data == 'prev') {
      this.startDateTasks = moment(this.startDateTasks).subtract(1, 'day').format('MM/DD/YYYY');
      this.endDateTasks = this.startDateTasks;
      requestCall = true;
    } else if (data == 'next') {//&& todayDate != this.endDateTasks
      this.startDateTasks = moment(this.startDateTasks).add(1, 'day').format('MM/DD/YYYY');
      this.endDateTasks = this.startDateTasks;
      requestCall = true;
    } else if (data == "today") {
      this.startDateTasks = moment().format('MM/DD/YYYY');//subtract(1, 'day')
      this.endDateTasks = this.startDateTasks;
      requestCall = true;
    }
    if (requestCall) {
      this.dateTasksList = moment(this.startDateTasks).format('MM/DD/YYYY');
      this.getDailytask(this.startDateTasks, this.endDateTasks);
    }

    // if (this.endDateTasks == todayDate) {
    //   $("#nextBtnTasks").css("cursor", "not-allowed");
    // } else {
    //   $("#nextBtnTasks").css("cursor", "pointer");
    // }
  }
}
