import { Component, OnInit } from '@angular/core';
import { TaskService } from 'src/services/task.service';
import { UserService } from 'src/services/user.service';
import { AppService } from 'src/app/app.service';
import * as moment from 'moment';
import { DashboardService } from 'src/services/dashboard.service';


@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.css']
})
export class DailyComponent implements OnInit {
  public startDate = moment().format('MM/DD/YYYY');
  public endDate = moment().format('MM/DD/YYYY');
  public selectDate: any;
  public userTasks: any = [];
  public userList: any = [];
  public taskHtml = "";
  public projectHtml = "";
  public hours = 0;
  public year: any = moment().format('YYYY');
  public estHours = 0;
  
  constructor(
    private appService: AppService,
    private taskService: TaskService,
    private userService: UserService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "Daily Report"
    });
    this.changeDate('yrs-day');
  }

  changeDate(data?: any) {
    let todayDate = moment().format('MM/DD/YYYY');
    let requestCall = false;
    if (data == 'prev') {
      this.startDate = moment(this.startDate).subtract(1, 'day').format('MM/DD/YYYY');
      this.endDate = this.startDate;
      requestCall = true;
    } else if (data == 'next' && todayDate != this.endDate) {
      this.startDate = moment(this.startDate).add(1, 'day').format('MM/DD/YYYY');
      this.endDate = this.startDate;
      requestCall = true;
    } else if (data == "yrs-day") {
      this.startDate = moment().format('MM/DD/YYYY');//subtract(1, 'day')
      this.endDate = this.startDate;
      requestCall = true;
    }
    if (requestCall) {
      $(".custom-loader").css("display", "table");
      $(".loader-response").css("display", "none");
      this.selectDate = moment(this.startDate).format('MM/DD/YYYY');
      this.getTasksList(this.startDate, this.endDate);
      this.getProjectsList(this.startDate, this.endDate);
    }

    if (this.endDate == todayDate) {
      $("#nextBtn").css("cursor", "not-allowed");
    } else {
      $("#nextBtn").css("cursor", "pointer");
    }
  }

  getTasksList(startDate, endDate) {
    this.taskHtml = "";
    this.userList = [];
    this.dashboardService.getDailyReport(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        let data = res.data;
        this.userTasks = data;
        console.log(this.userTasks);
        if (data.length == 0) {
          document.getElementById("no-data-found").innerHTML = "No Records Found.";
          document.getElementById("daily-task-list").innerHTML = "";         
          $(".custom-loader").css("display", "none");
          $(".loader-response").css("display", "block");         
        } else {
          this.userService.getUsersfordailyreport().subscribe(response => {
            if (response.status == "ok") {
              if (response.data.value.length > 0) {
                
                this.userList = response.data.value;
                this.taskHtml += "<table class='table table-bordered'><tr style='background-color:#d7d7d7;'><td style='width: 152px;'>Name</td><td style='width: 175px;'>Project Name</td><td style='width: 50px;'>Est</td><td style='width: 50px;'>Act</td><td>Description</td></tr>";
                for (let u = 0; u < this.userList.length; u++) {
                  this.taskHtml += "<tr><td style='width: 152px;'>" + this.userList[u].firstName + "</td><td colspan='4' style='padding:0 !important'><table class='table mb-0'>";
                  for (let t = 0; t < this.userTasks.length; t++) {
                    if (this.userTasks[t].userId == this.userList[u].userId) {
                      this.taskHtml += "<tr><td style='width: 175px;' class='border-top-0 border-left-0'>" +
                      "<img style='height: 30px; width: 30px;' src='" + (this.userTasks[t].projectLogo ? this.userTasks[t].projectLogo : '/assets/img/default-project.png') + 
                      "' onError=\"this.onerror=null;this.src='/assets/img/default-project.png'\" class='user-icon' /> " +
                      this.userTasks[t].projectName + 
                      "</td><td style='width: 50px;' class='border-top-0'>" + 
                      this.userTasks[t].estimatedHours + 
                      "</td><td style='width: 50px;' class='border-top-0'>" + 
                      this.userTasks[t].hours + 
                      "</td><td class='border-top-0 border-right-0'>" + 
                      " " + this.userTasks[t].taskTitle+"<br>"+
                      this.userTasks[t].taskDescription +"<br>" +
                      "<br>"+
                      "Task Log"+"<br>"+
                      this.userTasks[t].description + 
                      "</td></tr>";
                      this.hours += parseFloat(this.userTasks[t].hours);
                      this.estHours += parseFloat(this.userTasks[t].estimatedHours);
                    }
                  }
                  if (this.hours > 0) {
                    this.taskHtml += "<tr><th class='border-left-0 border-bottom-0'>Total Hours</th><th class='border-bottom-0'>" + this.estHours + "</th><th colspan='2' class='border-bottom-0 border-right-0'>" + this.hours + "</th></tr>";
                  } else {
                    this.taskHtml += "<tr><th class='border-0 text-danger'>No Entry</th></tr>";
                  }
                  this.taskHtml += "</table></td></tr>";
                  this.hours = 0;
                  this.estHours = 0;
                }
                this.taskHtml += "</table>";
                document.getElementById("daily-task-list").innerHTML = this.taskHtml;
                document.getElementById("no-data-found").innerHTML = "";              
                $(".custom-loader").css("display", "none");
                $(".loader-response").css("display", "block");             
              } else {

              }
            }
          }, error => {
            if (error.status == 401) { }
          });
        }
      }
    });
  }

  getProjectsList(startDate, endDate) {
    this.projectHtml = "";
    this.dashboardService.getProjectsTimeline(startDate, endDate).subscribe(res => {
      if (res.status == "ok") {
        let data = res.data;
        if (data.length > 0) {
          this.projectHtml += "<table class='table table-bordered'><tr style='background-color:#d7d7d7;'><th>Projects</th><th>Hours</th></tr>";
          for (var i = 0; i < data.length; i++) {
            this.projectHtml += "<tr><td style='width: 240px;'>" + data[i].projectName + "</td><td style='background-color: #f5f5f5;'>" + data[i].hours + "</td></tr>"
          }
          this.projectHtml += "</table>";
          document.getElementById("daily-project-list").innerHTML = this.projectHtml;
        } else {
          document.getElementById("daily-project-list").innerHTML = "";
        }
      }
    }, error => {
      if (error.status == 401) { }
    });
  }
}
