import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { UserService } from 'src/services/user.service';
import { NotificationService } from 'src/services/notification.service';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { GlobalVariable } from "src/app/globals";
import { HttpErrorResponse } from '@angular/common/http';
import { Constants } from 'src/services/constants.service';
import * as moment from 'moment';

// declare var require: any;
// const jsonfile = require('jsonfile')
// const file = '/assets/js/skills.json';
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class UserAddComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  userId: number = null;
  firstName: string = '';
  public userList: any = [];
  lastName: string = '';
  email: string = '';
  username: string = '';
  password: string = '';
  public selectedAssignee: any = "";
  confirmPassword: string = '';
  designation: string = '';
  accessLevel: string = 'developer';
  isActive: boolean = false;
  phoneNumber = "";
  public supervisor: number = 0;
  landline = "";
  address = "";
  profilePhoto: FileList;
  files: FileList;
  private baseUrl = GlobalVariable.BASE_API_URL;
  public URL: string = this.baseUrl + "api/FileUpload/useruploadfile?userId=";
  formData: FormData = new FormData();
  public attachDocumentName = [];
  //  public attachDocument: boolean = false;
  public isProfilePic: boolean = false;
  public userDoB = new Date();
  public userJoinDate = new Date();
  public skillsList = [];

  //skills multiselect-dropdown
  itemList = [];
  selectedItems = [];
  settings = {};
  itemListCount = 0;
  public year: any = moment().format('YYYY');

  constructor(
    private appService: AppService,
    private userService: UserService,
    private noti: NotificationService,
    private router: Router,
    private http: Http,
    private el: ElementRef) { }

  ngOnInit() {
    this.appService.headerTitle.emit({
      data: "People"
    });
this.getUser();
    this.getSkills();
  }

  getSkills() {
    this.userService.getSkills().subscribe(res => {
      if (res.status == "ok") {
        this.itemList = res.data;
        this.itemListCount = this.itemList.length;
      }
    }, error => {
      if (error.status == 401) { }
    });

    this.selectedItems = [];
    this.settings = {
      enableCheckAll: false,
      singleSelection: false,
      text: "Select Skills",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      // enableFilterSelectAll:false,
      addNewItemOnFilter: true
    };
  }
  onAddItem(data: string) {
    this.itemListCount++;
    this.itemList.push({ "id": this.itemListCount, "itemName": data });
    this.selectedItems.push({ "id": this.itemListCount, "itemName": data });
  }
  onItemSelect(item: any) {
  }
  OnItemDeSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  onDeSelectAll(items: any) {
  }

  addProfilePic(event) {
    this.formData.delete('profile')
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        $('#profile-viewer').attr('src', e.target.result);
      }
      reader.readAsDataURL(event.target.files[0]);
    }

    this.profilePhoto = event.target.files;
    for (let i = 0; i < this.profilePhoto.length; i++) {
      this.isProfilePic = true;
      this.formData.append('profile', this.profilePhoto[i], this.profilePhoto[i].name)
    }
  }
  getUser() {
    console.log('Fetching user list...');
    if (Constants.adminLevelUsers.length == 0) {
      this.userService.getAdminlevel().subscribe(response => {
        console.log('Admin level users fetched:', response);  // Debugging log
        if (response.status == "ok") {
          Constants.adminLevelUsers = this.userList = response.data.value;
          this.selectedAssignee = this.userList[0];
          this.supervisor = this.userList[0].userId;
          console.log('Initial selectedAssignee:', this.selectedAssignee);
        }
      }, error => {
        console.log('Error fetching admin level users:', error);
        if (error.status == 401) { /* Handle error */ }
      });
    } else {
      console.log('Using cached user list...');
      this.userList = Constants.adminLevelUsers;
      this.selectedAssignee = this.userList[0];
      this.supervisor = this.userList[0].userId;
    }
  }
  
  selectPeople(user) {
    console.log('User Selected:', user);  // Debugging log to check selected user
    this.selectedAssignee = user;  // Assign the selected user
  }
  
  
 
  

  addFiles(event) {
    if (this.attachDocumentName.length < 5) {
      this.files = event.target.files;
      for (let i = 0; i < this.files.length; i++) {
        this.attachDocumentName.push({ 'name': this.files[i].name }); //= this.files[i].name;
        this.formData.append('files', this.files[i], this.files[i].name);
      }
    } else {
      this.noti.notification("Error", "You Already Three Files Selected", "danger");
    }
  }

  uploadFiles() {
    let url = this.URL + this.userId;
    this.http.post(url, this.formData)
      .subscribe(respo => {
        this.noti.notification("Success", "User Added Successfully", "success");
        this.router.navigateByUrl('/user/list');
      }, (error: HttpErrorResponse) => {
        // console.error(error);
      });
  }

  addUser() {
    $(".btn-submit").prop('disabled', true);

    let isValid = this.validate();
    if (isValid) {
      var user = {
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        LandLine: '',
        Email: '',
        Username: '',
        Password: '',
        Designation: '',
        AccessLevel: '',
        Skills: "",
        IsActive: false,
        Address: '',
        supervisorId:0,

        Dob: new Date(),
        JoinDate: new Date()
      };
      user.FirstName = this.firstName.trim();
      user.LastName = this.lastName.trim();
      user.PhoneNumber = this.phoneNumber.trim();
      user.LandLine = this.landline.trim();
      user.Email = this.email.trim();
      user.Username = this.username.trim();
      user.Password = this.password.trim();
      user.Designation = this.designation.trim();
      user.AccessLevel = this.accessLevel;
      user.IsActive = this.isActive;
      user.Address = this.address.trim();
      user.Dob = this.userDoB;
      user.JoinDate = this.userJoinDate;
      user.supervisorId= this.selectedAssignee.userId;
      user.Skills = JSON.stringify(this.selectedItems);
      let newData = JSON.stringify(this.itemList);
      //update skills file
      this.userService.updateSkills(newData).subscribe(res => {
        if (res.status == "ok") {
        }
      }, error => {
        if (error.status == 401) { }
      });
      this.userService.addUser(user).subscribe(res => {
        if (res.status == "ok") {
          this.userId = res.data.userId;
          if (this.formData.has('files') || this.formData.has('profile')) {
            this.uploadFiles();
          } else {
            this.noti.notification("Success", "User Added Successfully", "success");
            this.router.navigateByUrl('/user/list');
          }
        }
      }, error => {
        if (error.status == 401) { }
      });
    } else {
      this.noti.notification("Error", "Please fill all details", "danger");
      $(".btn-submit").prop('disabled', false);
    }

  }

  validate() {
    if (!(!!this.firstName.trim())) {
      return false;
    }
    if (!!this.phoneNumber) {
      this.phoneNumber = this.phoneNumber.replace(/\s/g, '');
      if (!(/^((\+92)|(0092)|(92))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/.test(this.phoneNumber))) {
        return false;
      }
    }
    if (!!this.email.trim()) {
      this.email = this.email.replace(/\s/g, '');
      if (!(/^([a-zA-Z0-9_\.\-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/.test(this.email))) {
        return false;
      }
    }
    if (!(!!this.password.trim()) && !(!!this.confirmPassword.trim())) {
      return false;
    }
    if (this.password.trim() != this.confirmPassword.trim()) {
      return false;
    }
    if (!(!!this.designation.trim())) {
      return false;
    }
    if (!(!!this.accessLevel)) {
      return false;
    }
    return true;
  }

  deleteDocument(data) {
    var values = this.formData.getAll("files");
    values.splice(data, 1);
    this.attachDocumentName.splice(data, 1);
    this.formData.delete('files');
    values.filter(name => name !== 'Bob').forEach(name => this.formData.append('files', name));
  }
  deleteProfilePic() {
    this.formData.delete('profile');
    this.isProfilePic = false;
    $('#profile-viewer').attr('src', '/assets/img/add-img.png');
    $('#profilePhoto').val(null);
  }
}
