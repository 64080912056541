import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';
import * as error  from 'util';
import * as CryptoJS from 'crypto-js';
//import 'rxjs/add/operator/map';

@Injectable()

export class UserService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) {
  }
  private encryptionKey = 'hjguy6R%&^%&^';
  encrypt(clearText: string): string {
    const key = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    const encrypted = CryptoJS.AES.encrypt(clearText, key, {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  decrypt(cipherText: string): string {
    const key = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    const iv = CryptoJS.enc.Utf8.parse(this.encryptionKey);
    const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
      keySize: 256 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypted);
  }
  getuserbyemail(email: string) {
    let request = this.baseApiUrl + `api/users/findbyemail?email=${email}`;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions ({ headers: headers  });
    return this.http.post(request, options).pipe(map(response => response.json()));
}

  getuserAuthentication(user) {
    let request = this.baseApiUrl + 'api/users/authenticate', withCredentials: true ;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers, withCredentials: true });
    return this.http.post(request, user, options).pipe(map(response => { return response.json() }));
  }
  getuserStatus(id) {
    let request = this.baseApiUrl + `api/users/check-user-status?id=${id}`;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = this.authService.setHeaderAuth();
    return this.http.get(request, options).pipe(map(response => { return response.json() }));
  }
  getuserVerification(email,code) {
    let subUrl = `api/users/verify-code?email=${email}&code=${code}`;
    let request = this.baseApiUrl + subUrl;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(request, options).pipe(map(response => { return response.json() }));
  }
  //get all user
  getAll() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users';
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      })
    );
  }
  //get users where accesslevel != super_admin and isdeleted true
  getAdminlevel() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/getadminusers';
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }

  getsupervisor(userId) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/getsupervisor?userId=${userId}`;
    return this.http.get(request ,options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  //get only accesslevel != super_admin and admin and and isdeleted true
  getUsers() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/getusers';
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  getallUsers() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/getalllusers';
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  getUsersfordailyreport() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/getusersdailyreport';
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  addUser(user) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/';
    return this.http.post(request, user, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  getUser(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/' + id;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  getEditUser(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/getedituser/' + id;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  updateUser(user) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/' + user.userId;
    return this.http.put(request, user, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  updateLastLogin(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/updatelastlogin/' + id;
    return this.http.put(request, "", options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError((error: any) => {
        throw error;
      }
      )
    );
  }
  deleteUser(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/' + id;
    return this.http.delete(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  //get users all attachments
  getUsersFiles(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/getusersfiles?userId=${id}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  //delete user attachment
  deleteUserFile(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/deleteuserfile?id=${id}`;
    return this.http.delete(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  addLateCount(lateUser) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/users/addlateuser';
    return this.http.post(request, lateUser, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  deleteLateCount(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/deletelatecount?id=${id}`;
    return this.http.delete(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  getLateUsers(utcOffSet) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/getlateusers?utcOffSet=${utcOffSet}`;

    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  getLateuserList(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/getlateuser?id=${id}`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  getSkills() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/getskillslist`;
    return this.http.get(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  updateSkills(data) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/users/updateskillslist`;
    return this.http.put(request, data, options).pipe(
      map(
        response => {
          return response.json()
        },
        error => {
          return error.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
  // getLateCount(id) {
  //   let request = this.baseApiUrl + `api/users/getlateuserscount?id=${id}`;
  //   let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': true });
  //   let options = new RequestOptions({ headers: headers });
  //   return this.http.get(request, options).pipe(map(
  //   response => {
  //     return response.json()
  //   }
  // ),
  //   catchError(err => {
  //     return "error";
  //   }
  // ));
  // }
}
