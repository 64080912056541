import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GlobalVariable } from '../app/globals';
import { AuthenticateService } from './authenticate.service';
interface HiredStatusResponse {
  hiredCount: number;
  offerMadeCount: number;
  applications: any[];  // You can specify the actual type of application if needed
}
@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;

  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) {}



  createFeedback(task) {
    let options = this.authService.setHeaderAuth();
   
    let request = this.baseApiUrl + 'api/FeedBack/addfeedback';
   // let headers = new Headers({ 'Content-Type': 'application/json' });
    console.log(request)
    return this.http.post(request, task, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  
  // addTaskLog(taskLog) {
  //   let options = this.authService.setHeaderAuth();
  //   let request = this.baseApiUrl + 'api/tasks/log';
  //   // let headers = new Headers({ 'Content-Type': 'application/json' });
  //   // let options = new RequestOptions({ headers: headers });
  //   return this.http.post(request, taskLog, options).pipe(map(
  //     response => {
  //       return response.json()
  //     }
  //   ),
  //     catchError(err => {
  //       return "error";
  //     }
  //     ));
  // }


  getFeedback(id,filterStatus) {

    const options = this.authService.setHeaderAuth();
    const request = `${this.baseApiUrl}api/FeedBack/${id}/${filterStatus}`;
    
    return this.http.get(request, options).pipe(
      map(response => {
      
        return response.json(); // Ensure your backend sends a JSON response
      }),
      catchError(err => {
        console.error("Error in API call:", err);
        return (err); // Properly propagate the error
      })
    );
  }

  getAllFeedback(id) {
    
    const options = this.authService.setHeaderAuth();
    const request = `${this.baseApiUrl}api/FeedBack/allFeedBack${id}`;
    
    return this.http.get(request, options).pipe(
      map(response => {

        return response.json(); // Ensure your backend sends a JSON response
      }),
      catchError(err => {
        console.error("Error in API call:", err);
        return (err); // Properly propagate the error
      })
    );
  }
 
}
