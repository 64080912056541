import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { importType } from '@angular/compiler/src/output/output_ast';
import { GlobalVariable } from "src/app/globals";
import { AuthenticateService } from './authenticate.service';

@Injectable()

export class JobApplicationsService {
  private baseApiUrl = GlobalVariable.BASE_API_URL;
  constructor(
    private http: Http,
    private authService: AuthenticateService
  ) {
  }

  addApplications(application) {
    let options = this.authService.setHeaderAuth();

    let request = this.baseApiUrl + 'api/JobApplications';
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(request, application, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getApplications(pageNumber, pageSize, sortOrder, sortBy, search, id, filterStatus, filterUser) {
    let options = this.authService.setHeaderAuth();
 
    let request = this.baseApiUrl + `api/JobApplications?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}&userId=${id}&filterStatus=${filterStatus}&filterUser=${filterUser}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getTalentPool(pageNumber, pageSize, sortOrder, sortBy, search, id, filterStatus, filterUser) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getTalentPool?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}&userId=${id}&filterStatus=${filterStatus}&filterUser=${filterUser}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getApplication(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/` + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getApplicationByIndex(id, action) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getbyindex?id=${id}&action=${action}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getApplicationCount() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getapplicationcount`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getAssignedCount() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getassignedcount`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getUserList() {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getuserlist`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  updateApplication(application) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/JobApplications/' + application.id;
    // let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': true });
    // let options = new RequestOptions({ headers: headers });
    return this.http.put(request, application, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  noFurtherAction(application) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/JobApplications/nofurtheraction/' + application.id;
    return this.http.put(request, application, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  deleteApplication(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/JobApplications/' + id;
    // let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': true });
    // let options = new RequestOptions({ headers: headers });
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  addFeedback(feedback) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/JobApplications/resumefeedback';
    // let headers = new Headers({ 'Content-Type': 'application/json' });
    // let options = new RequestOptions({ headers: headers });
    return this.http.post(request, feedback, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getFeedback(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getresumefeedback/` + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getFeedbackFilter(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getresumefeedbackfilter/` + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  updateFeedback(feedback) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/JobApplications/resumefeedback/' + feedback.Id;
    // let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': true });
    // let options = new RequestOptions({ headers: headers });
    return this.http.put(request, feedback, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  deleteFeedback(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/JobApplications/deletefeedback/' + id;
    // let headers = new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': true });
    // let options = new RequestOptions({ headers: headers });
    return this.http.delete(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }
  getResumeLogs(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getresumelogs/` + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getResumeParseData(id) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getparsedata/` + id;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  postResumeEvaluation(resumeEvaluation) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + 'api/JobApplications/resumeevaluation';
    return this.http.post(request, resumeEvaluation, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }

  getResumeEvaluation(id, type) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/getresumeevaluation?resumeId=${id}&type=${type}`;
    return this.http.get(request, options).pipe(map(
      response => {
        return response.json()
      }
    ),
      catchError(err => {
        return "error";
      }
      ));
  }


  deleteEvFile(applicationId) {
    let options = this.authService.setHeaderAuth();
    let request = this.baseApiUrl + `api/JobApplications/deleteevfile?id=${applicationId}`;
    return this.http.delete(request, options).pipe(
      map(
        response => {
          return response.json()
        }
      ),
      catchError(err => {
        return "error";
      }
      )
    );
  }
}
